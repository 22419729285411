import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import { BannerSustentabilidade, AreaRPPN, ColetaSeletiva, EmpresaAmigaAbelha, EstacaoTratamento, UsinaSolar, ViveiroSertaoVerde } from '../../../images/Sustentabilidade';
import { Typography } from '@mui/material';

const Sustainability = () => {

    const data = [
        {
            id: 0, img: AreaRPPN, title: 'ÁREAS DE RPPN', subTitle: `Crescer preservando nossas áreas de mata nativa é um dos nossos princípios. Assim, destinamos 687 hectares de nossas terras como Reserva Particular do Patrimônio Natural (RPPN), com o objetivo de conservar a diversidade biológica da região.`
        },
        {
            id: 1, img: EstacaoTratamento, title: 'ESTAÇÕES DE TRATAMENTO', subTitle: `Possuímos duas estações de tratamento de água. Uma delas, para reuso de água no Packing House, possui capacidade de tratamento é de 10 mil litros de água por hora. A outra, para uso doméstico, pod tratar até 4,5 mil litros por hora.`
        },
        {
            id: 2, img: ViveiroSertaoVerde, title: 'VIVEIRO SERTÃO VERDE', subTitle: `Nosso viveiro, inaugurado em 2018, possui capacidade de produção de até 30 mil mudas nativas. Apenas em 2022, já reflorestamos uma área de mais de 8 mil hectares com diferentes espécies importantes para a região.`
        },
        {
            id: 3, img: EmpresaAmigaAbelha, title: 'EMPRESA AMIGA DA ABELHA', subTitle: `Somos certificados como Empresa Amiga da Abelha. Isso atesta nosso trabalho para promover o desenvolvimento sustentável de práticas agrícolas, a melhoria do meio ambiente, aumento do número de abelhas no Vale do São Francisco e suporte para as populações locais.`
        },
        {
            id: 4, img: ColetaSeletiva, title: 'COLETA SELETIVA', subTitle: `Na Agrodan, possuímos um centro de Gestão de Tratamento de Resíduos sólidos e recicláveis. Temos uma parceria com duas empresas em Petrolina: uma de reciclagem e um aterro sanitário, onde conseguimos dar descarte correto a 100% desses materiais.`
        },
        {
            id: 5, img: UsinaSolar, title: 'USINA SOLAR', subTitle: `Em 2022, inauguramos com uma Usina Solar, que abastecerá a Fazenda Brandões (nossa matriz), o Packing House, nossa Unidade Básica de Saúde e a vila que se localiza em seu entorno, com energia elétrica limpa e renovável.`
        },
    ]

    return (
        <div className={styles.container} >
            <HeaderBar img={BannerSustentabilidade} title='SUSTENTABILIDADE' subTitle={''} />

            <Typography className={styles.title} > O AGRONEGÓCIO PODE E DEVE SER SUSTENTÁVEL </Typography>
            <Typography className={styles.subTitle} >
                Acreditamos que o nosso segmento deve crescer de forma responsável, cuidando
                da sociedade e do meio ambiente. Para isso, adotamos diferentes práticas que
                fazem da Agrodan uma empresa que não só mantém, mas promove cuidados com a
                natureza, que você poderá saber mais a seguir.
            </Typography>

            {data.map((i, k) => (
                <div key={k} className={styles.card} >
                    <img src={i.img} alt="Foto" className={styles.img} />

                    <div className={styles.cardContext} >

                        <Typography className={styles.cardTitle} > {i.title} </Typography>
                        <Typography className={styles.cardSubTitle} > {i.subTitle} </Typography>

                    </div>

                </div>
            ))}

        </div>
    )
}

export default Sustainability;
